/* Banner.css */
.banner {
    background-image: url('../../../../../public/assets/img/banner/bannerBackground.png'); /* Update the path to the correct background image path */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 30px;
    padding: 0px, 64px, 0px, 64px    ;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #EBF1F2;
    width: 100%;
    height:570px;

  
  
}

.banner-content {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin-right: 40%;
}

.logo-section {
    display: flex;
    justify-content: center;
    margin-right: 20%; /* Add some space between the logo and the text */
}

.banner-logo {
    height: 125.21px;
    width: 502.93px;
}

.text-section {
    text-align: left; /* Align text to the left */
    color: #333;
}

.subtitle h2 {
    font-size: 18px;
    color: #000;
    margin: 10px 0;
    line-height: 30px;
    
}

.donate-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    text-decoration: none;
   
    align-items: center;
}

.donate-button:hover {
    background-color: #0056b3;
}
