
  .subscription-box-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    max-width: 1400px;
    height: 400px;
    margin: 0 auto;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    background-image: url('../../../public/assets/img/logobackground.png');/* Ensure this path is correct */
    background-size: 25%; /* Adjust size to contain the image within the container */
    background-repeat: no-repeat; /* Do not repeat the background image */
    background-position: right; /* Center the background image */
    border-radius: 10px; /* Optional: To match the overall design */
    width: 100%; /* Ensure the container takes up the full width of its parent */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }

  
  .left-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
    flex: 1;
   
  }
  
  .left-section h2 {
    color: #0078d7;
    margin-bottom: 10px;
    font-size: 35px;
    white-space: nowrap; /* Prevent text wrapping */
  }
  
  .left-section img {
    max-width: 299.07px;
    height: 100.36px;
  }
  
  .right-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
    max-width: 1400px; /* Adjust as necessary */
    
  }
  
  .subscription-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
  }
  
  .subscription-card p {
    margin: 5px 0;
    color: #000;
  }
  
  .subscription-form {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .subscription-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    outline: none;
    flex: 1;
    min-width: 200px; /* Ensure minimum width */
  }
  
  .subscription-form button {
    padding: 10px 20px;
    border: none;
    background-color: #0078d7;
    color: white;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
  }
  
  .subscription-form button:hover {
    background-color: #005bb5;
  }
  