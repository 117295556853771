
  .causes_boxed_bottom_wrapper {
    border-top: 2px solid #000;
    padding-top: 15px;
    margin-top: 17px;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.project-image {
  height: 200px;
  object-fit: cover;
}

.disabled {
  opacity: 0.5; /* or any other styles to visually represent a disabled state */
  pointer-events: none; /* to disable click events on the box */
}

.case_boxed_img_container {
  position: relative;
}
.box_cat_home{
  background-color: #0575D6;
  border-radius: 8px;
}
.box_goal{
  background-color: #EBF1F2;
  border-radius: 8px;
}

.shadowClass{
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.card-button_recentP {
  background-color: #0575D6;
  align-items: center;
  color: white;
  width: 134px;
  height: 43px;
  border: none;
  padding: 8px;
  margin-top: 20px;
  margin-left: 30%;
  border-radius: 10px;
  cursor: pointer;
  
}

.badge-top-right {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  /* Add more styling for the badge here */
}
.label {
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 0.85em;
  position: absolute;
  top: 10px;
  right: 10px;
}

.label-danger {
  background-color: #d9534f; /* Rouge pour 'Expiré' */
}

.label-warning {
  background-color: #f0ad4e; /* Orange pour 'à venir' */
}

.label-success {
  background-color: #5cb85c; /* Vert pour 'En cours' */
}
