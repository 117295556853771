.custom-slider {
  width: 100%;
  overflow: auto;
  display: flex;
  height: 100%;
  justify-content: space-between;
}
.mt-c {
  margin-top: -90px !important;
}

.about_top_boxed_icon div {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0px -4px 16px rgb(0 0 0 / 6%), 4px 8px 16px rgb(0 0 0 / 10%);
  background-color: white;
  margin: 0 auto;
  position: relative;
}
.about_top_boxed_icon div i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
}

ul.about_area_list {
  font-size: 18px;
  line-height: 26px;
  color: var(--paragraph-color);
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
}

.about_top_boxed_icon div ion-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
}

@media (max-width: 1400px) {
  .banner_one_text h1 {
    font-size: 55px;
  }
}

@media (max-width: 1199px) {
  .banner_one_text h1 {
    font-size: 45px;
    font-weight: 400;
    line-height: 70px;
  }
}

@media (max-width: 1440px) {
  .banner_one_text {
    padding-top: unset;
  }
}

@media (max-width: 992px) {
  .banner_one_text {
    padding-bottom: unset;
  }
}

.pointer{
  cursor: pointer;
}
