.back-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: black;
}

.selected-file{
    background-color: #e9ecef;
    padding: 12px 10px;
    border-radius: 20px;
    width: max-content;
}