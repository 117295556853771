.item-1 {
  background-color: bisque;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 10px;
  display: inline-block;
  white-space: nowrap;
}

.ml {
  margin-left: 30px;
}

.flex-grow {
  flex-grow: 1;
}

.red-border{
  border: 2px solid red !important;
  border-radius: 20px;
}

.modal-header .btn-close {
  margin: unset;
}