select.french + i.fa {
  position: absolute;
  right: 5px;
  pointer-events: none;
  padding-right: 5px;
}
select.arabic + i.fa {
  position: absolute;
  left: 15px;
  pointer-events: none;
  padding-right: 5px;
} 

select {
  position: relative;
  width: 100%;
}
