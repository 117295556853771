.transparency-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh; /* Full viewport height */
  padding: 7%;
  margin-top: 1px;
}

.line {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0%;
  margin-top: 0%;

  
}

.line img {
  width: 100%; /* Adjust width as needed */
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1;
}

.text {
  max-width: 50%;
  padding-right: 20px;
  text-align: left;
}

.title {
  color: #007bff; /* Adjust the color as needed */
  font-size: 46px;
  margin-bottom: 0px;
}
.soustitle {
  color: #007bff; /* Adjust the color as needed */
  font-size: 30px;
  margin-bottom: 0px;
}

.description {
  color: #333;
  font-size: 18px;
  margin-bottom: 10px;
}

.illustration {
  display: flex;
  justify-content: flex-end;
  max-width: 50%;
}

.illustration img {
  max-width: 100%;
  height: auto;
}
