.input-group-addon {
  width: 50px;
  background-color: #e4f5fb;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 13px 0px #0000000d;
  height: 60px;
}

input {
  width: 100%;
}

.input-group-addon i {
  color: #6c757ddb;
}
