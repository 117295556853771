.hexagon-grid {
  display: inline-block;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  width: 95%;
  height: auto;
  padding: 2px;
  border-radius: 24px;
  margin: -6% 2% 2% ;
  background-color: #fff;
  z-index: 2;
  flex-wrap: wrap;
  gap: 10px; /* Adjust spacing between items */
  position: relative
}

.hexagon-grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.hexagon-card {
  background: #fff;
  margin: 20px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hexagon-cat {
  width: 100px;
  height: 100px;
  background: #eae7e7;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.title h1 {
  text-align: center;
  font-size: 24px;
  color: #0072D7;
}

.hexagon-icon {
  width: 60px;
}

.hexagon-card h2 {
  font-size: 19px;
  color: #0575D6;
  word-spacing: 1px;
}

.hexagon-card p {
  margin: 0;
}

.card-button {
  background-color: #0575D6;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 16px;
  cursor: pointer;
}

.card-button:hover {
  background-color: #007bff;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .hexagon-card {
    max-width: 45%;
    margin: 15px;
  }

  .title h1 {
    font-size: 22px;
  }

  .hexagon-icon {
    width: 50px;
  }

  .hexagon-card h2 {
    font-size: 18px;
  }

  .card-button {
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .hexagon-card {
    max-width: 90%;
    margin: 10px;
  }

  .title h1 {
    font-size: 20px;
  }

  .hexagon-icon {
    width: 40px;
  }

  .hexagon-card h2 {
    font-size: 17px;
  }

  .card-button {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .hexagon-card {
    max-width: 100%;
    margin: 10px 5px;
  }

  .title h1 {
    font-size: 18px;
  }

  .hexagon-icon {
    width: 35px;
  }

  .hexagon-card h2 {
    font-size: 16px;
  }

  .card-button {
    padding: 6px;
  }
}
