.btn_navber_custom {
    color: var(--main-color);
    background-color: var(--white-color);
    transition: var(--transition);
    border: 2px solid var(--main-color);
    box-shadow: none;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    z-index: 0;
    height: 35.97px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn_navber_custom:hover {
    background: var(--main-color);
}