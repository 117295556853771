/* ComingSoon.css */
.coming-soon-container {
    text-align: center;
    padding: 15px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    max-width: 1190px; /* Set the specific width */
    height: 393px; /* Set the specific height */
    margin: 100px  auto;
    position: relative;
    background-image: url('../../../public/assets/img/logobackground.png');/* Ensure this path is correct */
    background-size: 30%; /* Adjust size to contain the image within the container */
    background-repeat: no-repeat; /* Do not repeat the background image */
    background-position: right; /* Center the background image */
    border-radius: 10px; /* Optional: To match the overall design */
    width: 100%; /* Ensure the container takes up the full width of its parent */
  }
  
  .background-image {
    position: absolute;
    width: 99%;
    height: 95%;
    top: 0;
    background-image: url('../../../public/assets/img/comingsoonVector.png'); /* Path to your vector image */
    background-size: cover; /* Cover the entire container */
    background-position: center ;/* Center the image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    transform: rotate(0.1deg); /* Rotate the image */
  }
  
  
  h1 {
    font-size: 55px;
    color: #606060;
  }
  
  .countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .time-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .hexagon-coming {
    width: 150px;
    height: 155px;
    background-color: #0072D7;
    font-family: 'Helvetica Neue LT Pro'; 
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    display: flex;
    align-items: flex-start; /* Align items at the start (top) */
    justify-content: center; /* Center items horizontally */
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 24px;
    padding-top: 30px; /* Add some padding to move the content slightly down */
  }
  
  .hexagon-inner {
    text-align: center;
  }
  
  .time {
    font-size: 31px;
  }
  
  .label  {
    font-family: 'Helvetica Neue LT Pro'; 
    font-size: 20px;
    text-align: center;
    color: #fff;
    margin-top: 50%; /* Adds space between the hexagon and the label */
  }
  