.stats-container {
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  width: 90%; /* Use a percentage to make it responsive */
  height: auto; /* Let height adjust automatically */
  margin: 40px auto;
  padding: 20px;
  position: relative;
}

.stats-container h2 {
  color: #007bff;
  margin-bottom: 40px;
  font-size: 26px;
  text-align: center;
}

.stats-grid {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.stats-item {
  flex: 1;
  margin: 0 10px;
  min-width: 200px; /* Ensure a minimum size */
  max-width: 300px; /* Optional maximum size */
  text-align: center;
}

.stats-item img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  color: #606060;
}

.stats-item h2 {
  font-size: 24px;
  margin: 10px 0;
  color: #0072D7;
}

.stats-item p {
  font-size: 16px;
  color: #606060;
}

.stats-item svg {
  color: #0072D7;
  margin-bottom: 20px;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .stats-container {
    width: 95%;
    padding: 20px;
  }
  
  .stats-item {
    max-width: 250px; /* Reduce size for medium screens */
    margin: 20px 10px; /* Add margin for better spacing */
  }

  .stats-container h2 {
    font-size: 24px;
  }

  .stats-item h2 {
    font-size: 22px;
  }

  .stats-item p {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .stats-container {
    width: 95%;
    padding: 16px;
  }
  
  .stats-item {
    max-width: 100%; /* Full width on smaller screens */
    margin: 20px 0; /* Increased margin for spacing */
  }

  .stats-container h2 {
    font-size: 22px;
  }

  .stats-item h2 {
    font-size: 20px;
  }

  .stats-item p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .stats-container {
    width: 100%;
    padding: 12px;
  }

  .stats-item {
    max-width: 100%;
    margin: 20px 0;
  }

  .stats-container h2 {
    font-size: 20px;
  }

  .stats-item h2 {
    font-size: 18px;
  }

  .stats-item p {
    font-size: 14px;
  }

  .stats-item img {
    width: 50px;
    height: 50px;
  }
}
