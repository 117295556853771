/* General Styles */
.colorClass p {
  color: black !important;
}

.blueClass {
  color: #0072D7;
  font-family: 'Helvetica Neue LT Pro'; 
  font-size: 48px;
  font-weight: 750;
  line-height: 57.6px;
  text-align: center;
}

.sizeClass {
  font-family: 'Helvetica Neue LT Pro'; 
  font-size: 20px;
  font-weight: 750;
  line-height: 24px;
  text-align: center;
}

.spacing p {
  margin-bottom: 16px; /* Adjust as needed */
}

.box {
  background: #fff;
  border-radius: 20px;
  margin: 20px;
  transition: all ease 0.2s;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 50px 0;
  gap: 10px;
}

.box:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}

.info-box-container {
  text-align: center;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-box-container h2 {
  color: #0072D7;
  margin-bottom: 10px;
}

.info-box-container h4 {
  margin-bottom: 30px;
  color: black;
}

.info-boxes {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.info-box {
  flex: 1;
  margin: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
}

.info-box img {
  height: 50px;
  margin-bottom: 10px;
}

.info-box h3 {
  color: #0072D7;
  font-size: 1.1em;
  margin-top: 0;
}

.hexagon-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 36px 0 0 0;
  filter: drop-shadow(4px 4px 8px rgba(9, 9, 9, 0.4));
  border-radius: 50px;
  gap: 5px;
}

.hexagon {
  position: relative;
  width: 288px;
  height: 320px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.hexagon:hover {
  transform: translateY(-6px);
  filter: drop-shadow(10px 10px 20px 2px rgba(15, 15, 15, 0.25));
}

.hexagon-content {
  text-align: center;
  padding: 3px;
  position: relative;
  z-index: 1;
}

.hexagon-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.hexagon h3 {
  color: #0056b3;
  font-size: 15px;
}

.hexagon p {
  color: #333;
  font-size: 12px;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .blueClass {
      font-size: 40px;
      line-height: 48px;
  }

  .sizeClass {
      font-size: 18px;
      line-height: 22px;
  }

  .info-box {
      flex: 1 1 45%;
  }

  .hexagon {
      width: 240px;
      height: 270px;
  }
}

@media (max-width: 992px) {
  .blueClass {
      font-size: 36px;
      line-height: 43.2px;
  }

  .sizeClass {
      font-size: 16px;
      line-height: 20px;
  }

  .info-box {
      flex: 1 1 100%;
  }

  .hexagon {
      width: 200px;
      height: 225px;
  }
}

@media (max-width: 768px) {
  .blueClass {
      font-size: 30px;
      line-height: 36px;
  }

  .sizeClass {
      font-size: 14px;
      line-height: 18px;
  }

  .box {
      margin: 10px;
      padding: 30px 0;
  }

  .info-box-container {
      padding: 15px;
  }

  .hexagon {
      width: 160px;
      height: 180px;
  }
}

@media (max-width: 576px) {
  .blueClass {
      font-size: 24px;
      line-height: 28.8px;
  }

  .sizeClass {
      font-size: 12px;
      line-height: 16px;
  }

  .box {
      margin: 5px;
      padding: 20px 0;
  }

  .info-box-container {
      padding: 10px;
  }

  .hexagon {
      width: 120px;
      height: 135px;
  }

  .hexagon h3 {
      font-size: 12px;
  }

  .hexagon p {
      font-size: 10px;
  }
}
