.contact_left_item,
.contact_area_left {
  height: 100%;
}

.link-container {
  border: 1px solid rgb(233, 236, 239);
  border-radius: 3px;
  padding: 10px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-profile {
  border: 1px solid rgb(233, 236, 239);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  /* text-align: center; */
  margin: 0 auto;
  position: relative;
}

.profile-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: white;
  display: flex;
  align-items: center;
}

.profile-menu li {
  float: left;
}

.profile-menu li a {
  display: block;
  color: grey;
  text-align: center;
  padding: 5px 16px;
  text-decoration: none;
  margin-right: 10px;
}

.profile-menu li a:hover:not(.active) {
  background-color: #0081af;
  border-radius: 50px;
  color: white;
}

.profile-menu .active {
  background-color: #0081af;
  border-radius: 50px;
  color: white;
}

.link-container {
  color: rgb(0 0 0 / 39%);
}

.filename {
  font-size: 12px;
  word-wrap: break-word;
  display: inline-block;
  max-width: 170px;
  word-break: break-all;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.small-text {
  font-size: 12px;
}
