.line-cat {
    width: 100%;
    display: flex;
    justify-content: center;
      
  }

.line-cat img {
    width: 5%; 
    height: 6px;
  }

  .colorClassCat{
    background-color: #EBF1F2;
    
  }

