.donation-page {
    font-family: Arial, sans-serif;
  }
  
  header {
    text-align: left;
    color: #0056b3;
  }
  
  h1 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .content {
    display: flex;
    justify-content: space-between;
  }
  
  .text-content {
    flex: 1;
    margin-right: 20px;
  }
  
  h2 {
    color: #0056b3;
    font-size: 18px;
    font-weight: bold;
  }
  
  p {
    color: #333;
    font-size: 14px;
  }
  
  .image-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-content img {
    max-width: 100%;
    height: auto;
  }
  
  footer {
    margin-top: 20px;
    text-align: left;
  }
  
  button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  button:hover {
    background-color: #003d80;
  }
  